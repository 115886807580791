.hero {
    background-size: cover;
    background-color: #284945;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    height: auto;
}

.hero h1 {
    font-size: 2.5rem;
}

.hero h2 {
    font-size: 1.25rem;
}

/* Navigation */
.hero nav {
    width: 100%;
    padding-top: 1rem;
}

.hero nav ul {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.hero nav li {
    width: fit-content;
}

.hero nav li span {
    padding: 0 .4rem;
}

/* Main Content */
.hero main {
    max-width: 40rem;
    line-height: 1.5;
    padding: 5rem 0;
}
 .hero main a {
     margin-top: 1rem;
     font-size: 1.25rem;
     display: inline-block;
     color: inherit;
 }

.hero main a:last-child {
    margin-left: 1rem;
}

/* Carousel */
.carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-color: white;
}
.carousel-container img {
    height: 70vh;
}

.carousel-container .arrow {
    height: 1rem;
}

.carousel-container .arrow-right {
    padding-right: 1rem;
}

.carousel-container .arrow-left {
    padding-left: 1rem;
}

.BrainhubCarousel__dot {
    margin-top: 5vh;
}
